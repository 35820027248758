// JavaScript Document

// Scripts written by Jacob Bearce | jacob@bearce.me

$(document).ready(function () {
    "use strict";
	$("select").not("select[multiple], .select select").each(function () {
		if ($(this).css("display") !== "none") {
			$(this).wrap("<div class='select'>");
		}
	});
});
